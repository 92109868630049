/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const getPriceArrays = (prices) => {
    const retail = [];
    const sale = [];

    if (!prices || !prices.length) {
        return {
            sale, retail,
        };
    }

    prices.forEach((price) => {
        if (price.type === 'retail') {
            retail.push(price);
        } else {
            sale.push(price);
        }
    });
    retail.sort((a, b) => a.value - b.value);
    sale.sort((a, b) => a.value - b.value);
    return {
        sale, retail,
    };
};

export const getMultiplePriceArrays = (products) => {
    const allProductsData = [];

    if (!products || !products.length) {
        return allProductsData;
    }

    const extractProductType = (product) => {
        const attributeArray = product?.attributes || [];
        const pType = attributeArray.find((elem) => elem?.name === 'PRODUCT_TYPE');
        if (pType?.value) {
            return pType.value;
        }
        return '';
    };

    products.forEach((product) => {
        const productData = {
            price: {
                retail: {},
                sale: {},
            },
            brand: product?.brand,
            partNumberFromPrice: product?.partNumber,
            availabilityIndicator: product?.availabilityIndicator,
            productType: extractProductType(product),
        };
        product?.prices?.forEach((price) => {
            if (price?.type === 'retail') {
                productData['price']['retail'] = [{ ...price }];
            } else {
                productData['price']['sale'] = [{ ...price }];
            }
        });

        allProductsData.push(productData);
    });

    return allProductsData;
};

export default {};
